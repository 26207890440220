<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-select
        label="Cliente/s"
        :items="clients"
        multiple
        item-text="name"
        item-value="id"
        v-model="formData.clients"
        :rules="[$data.$globalRules.required]"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-select
        label="Centro/s"
        :items="centers"
        multiple
        item-text="name"
        item-value="id"
        v-model="formData.centers"
        :rules="[$data.$globalRules.required]"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-select
        label="Género"
        :items="genders"
        v-model="formData.gender"
        :rules="[$data.$globalRules.required]"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field label="Nombre" v-model="formData.name" :rules="[$data.$globalRules.required]" />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="Apellidos"
        v-model="formData.surname"
        :rules="[$data.$globalRules.required]"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="Email (opcional)"
        v-model="formData.email"
        :rules="[$data.$globalRules.email]"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field label="Teléfono (opcional)" v-model="formData.telephone" />
    </v-col>
  </v-row>
</template>

<script>
import genders from "@/utils/helpers";

export default {
  props: {
    formData: { type: Object, required: true },
    centers: { type: Array, required: true },
    clients: { type: Array, required: true },
  },
  data: () => ({
    genders,
  }),
};
</script>

<style scoped></style>
